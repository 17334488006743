@media (max-width: 767px){
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: white;
    }
}

.modal-content > .modal-body > ul > li > span >.makered_bold {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: red;
    font-weight: bold;
}

.modal-content > .modal-body {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: #3d3d3d;
}

.modal-content > .modal-body > ul > li > span > p > .boxed {
    color: green;
}

.modal-content > .modal-body > ul > li > span > div > ul > li  {
    margin-left: 30px;
}

.modal-content > .modal-body > ul > li > span > div > ul > li > .boxed {
    color: green;
}

.modal-content > .modal-header > .modal-title {
    margin: 0;
    line-height: 1.428571429;
    font-size: 18px;
    font-weight: bold;
    color: #3E75B2;
}

.modal-content > .modal-body > ul > li {
    list-style: square;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
}

.billsearch-background .container .navbar>.container>.sd-header-ml-200{
    margin-right: 200px!important;
}
.billsearch-background .container .modal-body>h4 {
    font-size: 12px;
    font-weight: bold;
    color: #00246A;
    margin: 0 0 3px 0;
}

@media (max-width: 960px) {
    #payment_content {
        height: 100%;
        padding: 10px 10px 5px 5px;
    }
}

@media(max-width: 768px) {
    .billsearch-background .container {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.billsearch-background .container .navbar-inverse .navbar-nav>li>a {
    color: #ffffff;
    font-weight: bold;
}

.billsearch-background .container .navbar>.container>.navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
}

.billsearch-background .container .boxed {
    color: green;
}

.billsearch-background .container .makered_bold {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
}

.billsearch-background .container .modal-body {
    padding: 20px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
}

.billsearch-background .container .modal-body>ul {
    list-style: initial;
}

.billsearch-background .container .modal-body>ul>li {
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
    list-style: square;
}

.billsearch-background .container .modal-title {
    font-size: 18px;
    font-weight: bold;
    color: #3E75B2;
}

.billsearch-background .container .modal-sd-navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1050;
    display: none;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.billsearch-background .container .modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: black;
    opacity: 0.5;
}

@media (min-width: 768px) {
    .billsearch-background .container .navbar-nav {
        display: flex;
        align-items: center;
    }    
    .billsearch-background .container .m-top-10 {
        margin-top: 10px;
    }   
    .navbar-billsearch-content-right {
        float: right !important;
        margin-right: -15px;
    }
}

.billsearch-background .container .header-billsearch>.navbar {
    border-radius: 0%;
    min-height: 0px;
    margin-bottom: 0px;
    border: none;
}

.billsearch-background .container .header-billsearch>.navbar>.container {
    padding-right: 30px;
    padding-left: 0px;
    margin-left: 0px;
}

.billsearch-background .container .header-billsearch>.navbar>.container>.navbar-header {
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 15px;
}

.billsearch-background .container .header-billsearch>.navbar>.container>.navbar-header>.navbar-toggle {
    float: left;
}

.billsearch-background .container .progress-billsearch {
    height: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #028dce;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.billsearch-background .container .progress-billsearch>.progress-type {
    position: relative;
    top: 0px;
    left: 0px;
    font-weight: bold;
    text-align: center;
    vertical-align: central;
    padding: 0px 10px 0px 10px;
    color: rgb(255, 255, 255);
    height: 25px;
}

.billsearch-background .container .header-billsearch {
    display: inline;
}

.billsearch-background .container .navbar-billsearch {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: auto;
    height: 45px;
    padding-right: 200px;
    background-color: #222;
}

.billsearch-background .container .navbar-billsearch>* {
    margin-left: 35px;
    padding: 10px;
}

.billsearch-background .container .navbar-billsearch a {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.billsearch-background .container .navbar-billsearch a:hover {
    color: white;
    background-color: #444444;
    transition: 0.7s all linear;
}