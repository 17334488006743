.billsearch-background .container .search-result-tables-td>td {
    vertical-align: middle !important;
}
.billsearch-background .container .gridRowText.visible-xs > .btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.billsearch-background .container .sr-btm-rw-l:not(:last-child) {
    border-bottom: 1px solid #dddddd
}

.billsearch-background .container .mt-10p {
    margin-top: 10px;
}

.billsearch-background .container .td-width-95p {
    width: 95px;
}

.billsearch-background .container .input-width-120p {
    width: 120px;
}

.billsearch-background .container .input-width-220p {
    width: 220px;
}

.billsearch-background .container .tag-height-0 {
    height: 0;
}

.billsearch-background .container .text-options-title-color {
    color: red;
    font-size: 14px;
}

.billsearch-background .container .text-th-bg-color {
    background-color: #333000;
}

.billsearch-background .container .text-th-color {
    color: white;
}
.mb-20px{
    margin-bottom: 20px;
}

.billsearch-background .container #display-section-header {
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    -moz-border-radius: 8px 8px 0px 0px;
    border-radius: 8px 8px 0px 0px;
    text-align: center;
}

* {
    text-align: left;
}

@media (min-width: 960px) {
    #search_steps {
        -moz-border-radius: 0px 0px 15px 15px;
        border-radius: 0px 0px 15px 15px;
        border: 1px solid #CCC;
        /* width: 845px; */
        vertical-align: top;
        padding: 0px 9px 12px 9px;
    }
}

@media (min-width: 960px) {
    #wrapper {
        position: relative;
        width: 960px;
        margin: 0 auto 0 auto;
        text-align: left;
        background-color: white;
    }
}

@media (min-width: 960px) {

    .table thead>tr>th,
    .table tbody>tr>th,
    .table tfoot>tr>th,
    .table thead>tr>td,
    .table tbody>tr>td,
    .table tfoot>tr>td {
        padding: 4px;
        line-height: 1.428571429;
        vertical-align: middle;
        border-top: 0 solid #dddddd;
    }
}

a:active,
a:hover {
    outline: 0;
}

.billsearch-background .container .gridRowNumeric {
    text-align: right;
}

.billsearch-background .container .owner {
    max-width: 100px;
    overflow-wrap: break-word;
}

.billsearch-background .container .gridRowText {
    text-align: center;
}

.billsearch-background .container .gridRowText a.btn {
    width: 120px;
}

.billsearch-background .container .gridRowText .fa-icon {
    margin-right: 2px;
}
.table {
    width: 100%;
    margin-bottom: 20px !important;
    margin: 0;
    font-size: 11px;
    border: 1px solid #000;
}

.billsearch-background .container .defaulted {
    background: #c42f2f;
}

.billsearch-background .container .unsecured {
    background: #735696;
}

.billsearch-background .container .supplemental {
    background: #090;
}

.billsearch-background .container .secured {
    background: #028dce;
}

.billsearch-background .container .makered_bold {
    color: red;
    font-weight: bold;
}

/* //////////////////////////////////////////////////////////////////// */

.billsearch-background .container p {
    margin: 0 0 10px;
}

.billsearch-background .container .row-centered {
    text-align: center;
}

.billsearch-background .container .col-centered {
    display: inline-block;
    float: none;
    text-align: left;
    margin-right: -4px;
}

@media (min-width: 960px) {
    #payment_content {
        height: 100%;
        padding: 20px 20px 20px;
    }
}

.billsearch-background .container form {
    margin: auto;
    position: relative;
    width: 100%;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
    color: #3d3d3d;
    text-decoration: none;
}

.billsearch-background .container #search_steps {
    -moz-border-radius: 0px 0px 15px 15px;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #CCC;
    /* width: 845px; */
    vertical-align: top;
    padding: 0px 9px 12px 9px;
}

.billsearch-background .container .btn-custom {
    height: inherit;
    width: 150px;
    min-width: fit-content;
    margin: 0px 10px 10px 0px;
    background-color: #52a0c4;
    border-color: #52a0c4;
}

.billsearch-background .container .step {
    margin-top: 0px;
    padding: 3px 30px 2px 30px;
    text-align: center;
    font-size: 12px;
    line-height: 24px;
}

.billsearch-background .container .step .stepbox {
    background: #e1e1e1;
    min-height: 50px;
    text-align: center;
    word-wrap: normal;
}

.billsearch-background .container .step .activestep {
    background: #51a5cc;
    color: #fff;
    vertical-align: middle;
    font-weight: bold;
}

.billsearch-background .container .panel-group .panel {
    overflow: hidden;
}

.billsearch-background .container .panel-heading a.collapsed:after {
    content: "\e080";
}

.billsearch-background .container .panel-heading a:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: white;
}

.billsearch-background .container .searchform_header_bill {
    background: #deb74d;
    padding: 5px;
    margin: 0px 0px 8px 0px;
    color: #fff;
}

.billsearch-background .container .searchform_header_mail {
    background: #028dce;
    padding: 5px;
    margin: 0px 0px 8px 0px;
    color: #fff;
}

.billsearch-background .container .searchform_header_unsecured {
    background: #735696;
    padding: 5px;
    margin: 0px 0px 8px 0px;
    color: #fff;
}

.billsearch-background .container .accordion-search-options .control-label {
    font-weight: normal;
}

.billsearch-background .container .no-result-info {
    width: 100%;
    text-align: center;
    padding: 7px;
    color: red;
    font-weight: bold;
}

.billsearch-background .container .view-bill-cell {
    text-align: left;
    padding-left: 5px;
}

.billsearch-background .container .action-cell {
    text-align: right;
    padding-right: 5px;
    width: 150px;
}

.billsearch-background .container .payments-disabled-message {
    text-align: center;

}

@media (min-width:768px) {
    .billsearch-background .container .btn-width-130 {
        width: 130px;
    }  
}

.billsearch-background .container .td-vert-aln-center {
    vertical-align: middle;
}