.success-btn-width{
    min-width: 6.5em;
}
.margin-bottom-5em{
    margin-bottom: 0.5em;
}
.wrap-overflow {
    overflow-wrap: break-word;
}

.main-nav li .glyphicon {
    margin-right: 10px;
}

.mb-30 {
    margin-bottom: 30px;
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
    background-color: #4189C7;
    color: white;
}

/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

@media (min-width: 768px) {
    /* On small screens, convert the nav menu to a vertical sidebar */
    .main-nav {
        height: 100%;
        width: calc(25% - 20px);
    }
    .main-nav .navbar {
        border-radius: 0px;
        border-width: 0px;
        height: 100%;
    }
    .main-nav .navbar-header {
        float: none;
    }
    .main-nav .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0px;
    }
    .main-nav .navbar ul {
        float: none;
    }
    .main-nav .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }
    .main-nav .navbar li a {
        padding: 10px 16px;
        border-radius: 4px;
    }
    .main-nav .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .customamount {
      padding: 6px 6px
    }
}

html, body {
    min-width: 370px;
    width: auto !important;
    height: 100%;
}

.btn-group {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
}

    /* .btn-group .btn-justified {
        margin-left: 2.5px;
        margin-right: 2.5px;
        margin-top: 2.5px;
        margin-bottom: 2.5px;
    } */

@media print {
    .navbar {
        display: block;
        border-width: 0 !important;
    }

    .navbar-toggle {
        display: none;
    }
}

@media ( max-width: 425px) {
    .btn-justified, .btn-group-justified-sm-12 {
        width: 99%;
    }

    .btn-group-justified-sm-6 {
        width: 35%;
        float: none;
    }
}

.logo .navbar-brand {
    padding: 0px 15px 0px 0px;
}

    .logo .navbar-brand > img {
        height: 100%;
        padding: 0px;
        margin-right: 30px;
        width: auto;
    }

.scrollable {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pay-method-icon {
    background: url("../images/sprite.css.svg");
    background-repeat: no-repeat;
    display: inline-block;
}

.pmtype-amex {
    background-position: -160px -86px;
    width: 40px;
    height: 25.5px;
}

.pmtype-discover {
    background-position: 0px -60px;
    width: 40px;
    height: 25.5px;
}

.pmtype-mastercard {
    background-position: -80px -60.5px;
    width: 40px;
    height: 25px;
}

.pmtype-googlepay {
    background-position: -120px -213px;
    width: 40px;
    height: 25px;
}

.pmtype-applepay {
    background-position: -160px -213px;
    width: 40px;
    height: 25px;
}

.pmtype-visa {
    background-position: -120px -9.5px;
    width: 40px;
    height: 25px;
}

.pmtype-ach {
    background-position: -200px -213px;
    width: 40px;
    height: 25px;
}

.pmtype-generic-card {
    background-position: 0px -164px;
    width: 40px;
    height: 25px;
}

.pmtype-amex-disabled {
    background-position: -120px -60px;
    width: 40px;
    height: 25.5px;
}

.pmtype-discover-disabled {
    background-position: -40px -112px;
    width: 40px;
    height: 25px;
}

.pmtype-mastercard-disabled {
    background-position: -40px -137px;
    width: 40px;
    height: 25.5px;
}

.pmtype-visa-disabled {
    background-position: 0px -9.25px;
    width: 40px;
    height: 25.5px;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

.cf {
    border-radius: 4px;
    overflow: hidden;
}

.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}

.inner {
    max-width: 820px;
    margin: 0 auto;
}

.breadcrumbs ul {
    border-top: 1px solid #2c699e;
    border-bottom: 1px solid #2c699e;
    background-color: #337ab7;
    border-left: 1px solid #2c699e;
    border-right: 1px solid #2c699e;
}

.breadcrumbs li {
    float: left;
    width: 16.66%;
}

    .breadcrumbs li.disabled {
        opacity: 0.65;
        cursor: not-allowed;
        pointer-events: none;
    }

.breadcrumbs a {
    position: relative;
    display: block;
    padding: 20px;
    padding-right: 0 !important;
    /* important overrides media queries */
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

    .breadcrumbs a:hover {
        background: #5094ce;
    }

    .breadcrumbs a.active {
        color: #ffffff;
        background-color: #5094ce;
    }

    .breadcrumbs a.disabled {
        cursor: not-allowed;
        color: #cccccc;
    }

    .breadcrumbs a span:first-child {
        display: inline-block;
        text-align: center;
        width: 22px;
        height: 22px;
        line-height: 20px;
        margin-right: 5px;
        border: 2px solid #2c699e;
        border-radius: 50%;
        background-color: #5094ce;
    }

    .breadcrumbs a.active span:first-child {
        color: #ffffff;
        border-color: #2c699e;
        background-color: #5094ce;
    }

    .breadcrumbs a.disabled span:first-child {
        color: #cccccc;
    }

    .breadcrumbs a:before,
    .breadcrumbs a:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;
        display: block;
        width: 0;
        height: 0;
        border-top: 31px solid transparent;
        border-bottom: 32px solid transparent;
        border-left: 16px solid transparent;
    }

    .breadcrumbs a:before {
        margin-left: 1px;
        border-left-color: #2c699e;
    }

    .breadcrumbs a.disabled:before {
        margin-left: 1px;
    }

    .breadcrumbs a:after {
        border-left-color: #337ab7;
    }

    .breadcrumbs a:hover:after {
        border-left-color: #5094ce;
    }

    .breadcrumbs a.active:after {
        border-left-color: #5094ce;
    }

.breadcrumbs li:last-child a:before,
.breadcrumbs li:last-child a:after {
    display: none;
}

@media (max-width: 720px) {
    .breadcrumbs a {
        padding: 15px;
    }

        .breadcrumbs a:before,
        .breadcrumbs a:after {
            border-top-width: 26px;
            border-bottom-width: 26px;
            border-left-width: 13px;
        }
}

@media (max-width: 620px) {
    .breadcrumbs a {
        padding: 10px;
        font-size: 12px;
    }

        .breadcrumbs a:before,
        .breadcrumbs a:after {
            border-top-width: 22px;
            border-bottom-width: 22px;
            border-left-width: 11px;
        }
}

@media (max-width: 520px) {
    .breadcrumbs a {
        padding: 5px;
    }

        .breadcrumbs a:before,
        .breadcrumbs a:after {
            border-top-width: 16px;
            border-bottom-width: 16px;
            border-left-width: 8px;
        }

    .breadcrumbs li a span:first-child {
        display: block;
        margin: 0 auto;
    }

    .breadcrumbs li a span:last-child {
        display: none;
    }
}

/* overlay and spinner */
.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 1000;
    top: 0;
    left: 0;
    opacity: .5;
}

.overlay-content {
    height: 200px;
    position: absolute;
    width: 200px;
    margin-top: -100px;
    top: 50%;
    margin-left: -100px;
    left: 50%;
    font-size: 100px;
    color: white;
    padding-left: 50px;
    padding-top: 30px;
}

.overlay-content .spinner {
    animation: spin 1000ms infinite linear;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.mngacctsform table > tbody > tr > td {
    vertical-align: middle !important;
    padding-left: 10px !important;
}

.react-datepicker-component .react-datepicker-input {
    background: transparent !important; 
    border: 1px solid #ccc !important;  
    height: 34px !important; 
}

.react-datepicker-component .react-datepicker-input input {
    font-weight: normal !important;
}

.form-inline .checkbox input[type="checkbox"] {
    position: absolute !important;
    margin-left: -20px !important;
}

.maintenance-block {
    min-height: 80vh;
}

.convenience-fee-label{
    font-weight: bold;
}

.r-table {
    overflow-x: auto;
}
@media only screen and (max-width: 600px) {

    
    .flex-3rd {
        flex: 0 0 33.333333%;
        margin-top: 1px;
    }
    
    .flex-1n {
        flex: 0 0 100%;
        margin-top: 1px;
    }
    
    .r-flex-stepper {
        display: flex;
        flex-wrap: wrap;
    }
    .payment_text { 
        padding: 0 10px 0 10px;
    }
    .body{
        padding-top: 0px !important;
    }
  }
