.billsearch-background {
    background-color: #3e75b2;
}

.billsearch-background .container .billsearch-wrap-overflow {
    background-color: white;
    min-height: calc(100vh - 167px);
}

.billsearch-bar-top {
    height: 10px;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    background-color: #E0B00A;
}

.billsearch-footer {
    background-color: #E0B00A;
    width: 100%;
    height: 112px;
    padding: 5px 0px;
}

.billsearch-footer-row {
    height: 24px;
    color: white;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
}

.billsearch-background .container .payment_header {
    display: block;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #00246a;
    margin-bottom: 15px;
}

.billsearch-background .container .payment_text {
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 12px;
    line-height: 15px;
    text-shadow: none;
}

.billsearch-background .container .payment_text p {
    margin: 12px 0px 12px 0px;
}

.billsearch-background .container .payment_text .boxed {
    color: green;
}

.billsearch-background .container .convenience-fee-list {
    text-transform: none;
    padding-left: 0;
}

.billsearch-background .container .convenience-fee-list li {
    list-style-type: none;
    margin-left: 30px;
}

.billsearch-background .container .convenience-fee-label {
    font-weight: bold;
    color: red;
}

.billsearch-background .container .alert.alert-warning {
    margin: 0px;
    border-radius: 0px;
}

.billsearch-background .container .maintenance-block {
    color: white;
}

.billsearch-background .container .stepper {
    margin-bottom: 10px;
}

@media(max-width: 768px) {
    .billsearch-background .container .panel-heading a.collapsed:after {
        content: "\E114" !important;
    }
}