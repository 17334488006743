@media (max-width: 767px){
    .btn-remove-mobile {
        display: flex;
        align-items: center;
    }
}

.billsearch-background .container .cart-table {
    border-collapse: collapse;
}

.billsearch-background .container .cart-table-header {
    color: white;
    background-color: #333000;
}

.billsearch-background .container .cart-table-footer {
    color: white;
    background-color: #333000;
}

.billsearch-background .container .cart-table-row {
    border-bottom: 1px solid #dddddd;
}

.billsearch-background .container .cart-table-row .fa-icon {
    margin-right: 2px;
}

.billsearch-background .container .cart-total {
    text-align: end;
    font-weight: 800;
}